/*
 * Licensed to the Apache Software Foundation (ASF) under one
 * or more contributor license agreements.  See the NOTICE file
 * distributed with this work for additional information
 * regarding copyright ownership.  The ASF licenses this file
 * to you under the Apache License, Version 2.0 (the
 * "License"); you may not use this file except in compliance
 * with the License.  You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 */

@import 'common/variable';
@import '~bootstrap/scss/bootstrap';
@import '~bootstrap-icons';

.btn-link {
  color: #1677ff !important;
}

.link-secondary {
  color: rgba(0, 0, 0, 0.35) !important;
}

.text-success,
.link-success {
  color: #1677ff !important;
  &:hover {
    color: #1677ff !important;
  }
}

.btn-primary {
  background: #1677ff !important;
  border-color: #1677ff !important;
  &:hover {
    background: #0659cd !important;
    border-color: #0659cd !important;
  }
}

.btn-outline-primary {
  border-color: #1677ff !important;
  color: #1677ff !important;
  &:hover {
    background: #1677ff !important;
    color: #fff !important;
  }
}

.bg-gray-300 {
  background-color: $gray-300;
}

.focus {
  color: $input-focus-color !important;
  background-color: $input-focus-bg !important;
  border-color: $input-focus-border-color !important;
  outline: 0 !important;
  @if $enable-shadows {
    @include box-shadow($input-box-shadow, $input-focus-box-shadow);
  } @else {
    box-shadow: $input-focus-box-shadow !important;
  }
}

html,
body {
  padding: 0;
}

a {
  text-decoration: none;
}
// If the image does not have a `src` attribute, it must break and may not trigger the `onerror` event.
// With or without the `alt` attribute, it is visually hidden directly.
img[src=''] {
  visibility: hidden !important;
}

.btn-link {
  text-decoration: none;
}

.fs-12 {
  font-size: 0.75rem;
}

.small {
  font-size: 0.875rem;
}

.pic-auth-modal {
  width: 25%;
}

.no-toggle {
  &::after {
    display: none;
  }
}

.unread-dot {
  width: 18px;
  height: 18px;
  border-radius: 50%;
  position: absolute;
  left: 15px;
  top: 0;
  border: 1px solid #fff;
}

.badge-tag {
  display: inline-block;
  font-size: 14px;
  background: #ecf0ff !important;
  padding: 0 7px 1px;
  color: #1677ff !important;
  border: 1px solid transparent;
  &:hover {
    color: $blue-700;
    background: $blue-100;
  }
}

.badge-tag-required {
  background: $gray-200;
  color: $gray-700;
  border: 1px solid $gray-400;
  &:hover {
    color: $gray-700;
    background: $gray-300;
  }
}

.badge-tag-reserved {
  background: rgba($orange-100, 0.5);
  color: $orange-700;
  border: 1px solid $orange-100;
  &:hover {
    color: $orange-700;
    background: rgba($orange-100, 1);
  }
}

.divide-line {
  border-bottom: 1px solid rgba(33, 37, 41, 0.25);
}

#root {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}
#root > footer {
  margin-top: auto !important;
  padding-bottom: constant(safe-area-inset-bottom);
  padding-bottom: env(safe-area-inset-bottom);
}

.bg-f5 {
  background-color: #f5f5f5;
}

.btn-no-border,
.btn-no-border:hover,
.btn-no-border:focus,
.btn-no-border:active:focus {
  border: none;
  box-shadow: none;
}

.pointer:hover {
  cursor: pointer;
}

.resize-none {
  resize: none;
}

.split-dot {
  margin-left: 0.25rem !important;
  margin-right: 0.25rem !important;
  &:before {
    content: '\2022';
    color: #6c757d;
  }
}

.name-ellipsis {
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.text-truncate-1,
.text-truncate-2,
.text-truncate-3,
.text-truncate-4 {
  display: -webkit-box;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
}

.text-truncate-2 {
  -webkit-line-clamp: 2;
}
.text-truncate-3 {
  -webkit-line-clamp: 3;
}
.text-truncate-4 {
  -webkit-line-clamp: 4;
}

.last-p {
  p:last-child {
    margin-bottom: 0;
  }
}

.me-10 {
  margin-right: 10px;
}

.warning {
  background-color: #fff3cd80;
}

.fit-content {
  height: fit-content;
  width: fit-content;
  flex: none;
}

// fix bug for React-Bootstrap Form.Text
.form-text {
  display: inline-block;
}

.fmt {
  width: 100%;
  h1 {
    @extend .fs-3;
    margin-top: 2rem;
  }
  h2 {
    @extend .fs-4;
    margin-top: 2rem;
  }
  h3 {
    @extend .fs-5;
    margin-top: 2rem;
  }
  h4 {
    @extend .fs-6;
    margin-top: 2rem;
  }
  h5 {
    @extend .small;
    margin-top: 2rem;
  }
  h6 {
    @extend .fs-12;
    margin-top: 2rem;
  }
  img {
    max-width: 100%;
  }
  video {
    max-width: 100%;
  }
  p {
    > code {
      background-color: #e9ecef;
      color: #212529;
      padding: 2px 4px;
      border-radius: 0.25rem;
    }
  }
  pre {
    background-color: #e9ecef;
    border-radius: 0.25rem;
    padding: 1rem;
  }
  blockquote {
    border-left: 0.25rem solid #ced4da;
    padding: 1rem;
    color: #6c757d;
    background-color: #e9ecef;
    p {
      color: $body-color;
    }
    > p:last-child {
      margin-bottom: 0;
    }
  }
  table {
    td,
    th {
      word-break: initial;
    }
  }
}

.upload-img-wrap {
  flex-grow: 1;
  height: 128px;
}

.review-text-delete {
  color: #842029;
  background-color: #f8d7da;
  text-decoration: line-through;
  .review-text-add {
    text-decoration: none;
  }
}

.review-text-add {
  color: #0f5132;
  background-color: #d1e7dd;
  text-decoration: none !important;
}

.rotate-90-deg {
  display: inline-block;
  transform: rotate(90deg);
  transition: transform 0.2s;
}
.rotate-0-deg {
  display: inline-block;
  transform: rotate(0deg);
  transition: transform 0.2s;
}

.pre-line {
  white-space: pre-wrap;
}

.reset-p {
  p {
    margin-bottom: 0;
  }
}

@keyframes bg-fade-out {
  0%,
  25% {
    background-color: var(--bs-highlight-bg);
  }
  100% {
    background-color: transparent;
  }
}
.bg-fade-out {
  animation: bg-fade-out 2s ease 0.3s;
}

.btnSvg,
.btnSvg:hover {
  display: inline-block;
  font-size: 16px;
  width: 16px;
  height: 16px;
  fill: currentColor;
  vertical-align: -0.125em;
}

.max-width-200 {
  max-width: 200px;
}

.w-e-modal .btn-close svg {
  display: none;
}

.editor-content-view {
  transform: translateZ(0); /* 防止脱离文档流的关键 */
  overflow: hidden;
}

.editor-content-view img,
.editor-content-view svg,
.w-e-text-container img,
.w-e-text-container svg {
  vertical-align: bottom !important;
}

.sticky-top {
  z-index: 30;
}

.editor-content-view img {
  max-width: 100%;
}

.editor-content-view p,
.editor-content-view li {
  white-space: pre-wrap; /* 保留空格 */
}

.editor-content-view blockquote {
  border-left: 8px solid #b4d5ff;
  padding: 10px 10px;
  margin: 10px 0;
  background-color: #f6f2f0;
}

.editor-content-view code {
  border-radius: 4px;
  font-family: Consolas, Monaco, Andale Mono, Ubuntu Mono, monospace;
  hyphens: none;
  line-height: 1.5;
  margin: 0.5em 0;
  overflow: auto;
  padding: 1em;
  tab-size: 4;
  text-align: left;
  text-shadow: 0 1px #fff;
  white-space: pre;
  word-break: normal;
  word-spacing: normal;
  background-color: #f5f2f0;
  border: 1px solid #e8e8e8;
  display: block;
}
.editor-content-view pre > code {
  display: block;
  padding: 10px;
}

.editor-content-view table {
  border-collapse: collapse;
}
.editor-content-view td,
.editor-content-view th {
  border: 1px solid #ccc;
  min-width: 50px;
  height: 20px;
}
.editor-content-view th {
  background-color: #f1f1f1;
}

.editor-content-view ul,
.editor-content-view ol {
  padding-left: 20px;
}

.editor-content-view input[type='checkbox'] {
  margin-right: 5px;
}

.editor-content-view hr {
  border-color: #ccc !important;
  height: 1px !important;
}

video {
  max-width: 100%;
}

@media (min-width: 1400px) {
  .container-xxl,
  .container-xl,
  .container-lg,
  .container-md,
  .container-sm,
  .container {
    max-width: 1590px !important;
  }
}

@media screen and (max-width: 1499.9px) {
  .main-side-nav {
    width: 17%;
  }
  .main-page-warp {
    width: 83%;
  }
}

@media screen and (max-width: 1449.9px) {
  .main-side-nav {
    width: 19%;
  }
  .main-page-warp {
    width: 81%;
  }
}

@media screen and (max-width: 1399.9px) {
  .main-side-nav {
    width: 23%;
  }
  .main-page-warp {
    width: 77%;
  }
}

// @media screen and (max-width: 1349.9px) {
//   .main-side-nav {
//     width: 23%;
//   }
//   .main-page-warp {
//     width: 77%;
//   }
// }

@media screen and (max-width: 1299.9px) {
  .main-side-nav {
    width: 24%;
  }
  .main-page-warp {
    width: 76%;
  }
}

@media screen and (max-width: 1249.9px) {
  .main-side-nav {
    width: 23%;
  }
  .main-page-warp {
    width: 77%;
  }
}

@media screen and (max-width: 1199.9px) {
  .main-side-nav {
    width: 26.5%;
  }
  .main-page-warp {
    width: 73.5%;
  }
}

@media screen and (max-width: 991.9px) {
  .main-side-nav,
  .main-page-warp {
    width: 100%;
  }
}


.nav-plates {
  margin-bottom: 40px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  // grid-template-rows: repeat(3, 1fr);
  gap: 10px;
  .nav-plate {
    display: flex;
    align-items: center;
    gap: 12px;
    height: 80px;
    padding-left: 16px;
    border-radius: 10px;
    background: #f7f7f7;
    img {
      width: 48px;
      height: 48px;
    }
    .nav-plate-right {
      display: flex;
      flex-direction: column;
      gap: 5px;
      .nav-plate-name {
        font-weight: bold;
        font-size: 18px;
        line-height: 22px;
        color: #000000;
      }
      .nav-plate-desc {
        font-size: 12px;
        line-height: 14px;
        color: rgba(0, 0, 0, 0.65);
      }
    }
  }
}

@media (max-width: 767px) {
  .nav-plates {
    grid-template-columns: repeat(2, 1fr);
  }
}

.btn-group {
  .btn.active {
    background: #979797;
  }
  .btn-outline-secondary {
    border-color: #979797;
    &:hover {
      background: #979797;
    }
  }
}

.list-group-item {
  .pin-fill {
    padding: 2px 4px;
    font-size: 17px;
    background: #ff6700;
    font-weight: bold;
    color: #fff;
    border-radius: 3px;
  }

  .list-item-user-name {
    margin-right: 8px;
    span {
      color: #000;
      margin-right: 0;
    }
  }
  .text-secondary {
    color: rgba(0, 0, 0, 0.35) !important;
  }
  .sussess-tag {
    width: 66px;
    height: 24px;
    border-radius: 4px;
    text-align: center;
    line-height: 24px;
    background: #ffefe4;
    color: #ff6700;
    font-size: 14px;
    user-select: none;
  }
  .question-tags {
    margin-top: 18px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 6px;
    .badge-tag {
      font-size: 14px !important;
      color: rgba(0, 0, 0, 0.65) !important;
      background: rgba(0, 0, 0, 0.05) !important;
    }
  }
}

.tag-detail-top {
  display: flex;
  justify-content: center;
  flex-direction: column;
  flex-direction: 14px;
  gap: 20px;
  height: 150px;
  padding: 0 30px;
  margin-bottom: 30px;
  border-radius: 7px;
  background: #f7f7f7;
  .tag-detail-title {
    display: flex;
    align-items: center;
    gap: 20px;
  }
  img {
    width: 48px;
    height: 48px;
  }
  .tag-detail-name {
    font-size: 30px;
    line-height: 36px;
    font-weight: bold;
  }
  .tag-detail-excerpt {
    display: flex;
    align-items: center;
    font-size: 16px;
    line-height: 19px;
    padding-left: 70px;
    color: rgba(0, 0, 0, 0.65);
    gap: 6px;
    .bi-arrow-right-circle {
      color: rgba(0, 0, 0, 0.65) !important;
    }
  }
}

.detail-actions {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 20px;
  .detail-actions-samll {
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
  }
}

.questionDetailVid {
  height: 12px;
  width: 100%;
  background: #f7f7f8;
  margin-top: 30px;
  position: relative;
  &::before {
    content: '';
    position: absolute;
    left: -24px;
    height: 100%;
    width: 24px;
    background: #f7f7f8;
  }
}

@media (max-width: 991px) {
  .questionDetailVid {
    &::before {
      display: none;
    }
  }
}

.answer-actions {
  display: flex;
  align-items: center;
  gap: 6px;
  .btn-outline-primary {
    background: #ecf0ff !important;
    border-color: #ecf0ff !important;
    &:hover {
      background: #1677ff !important;
    }
  }
  .active {
    background: #1677ff !important;
    border-color: #1677ff !important;
    color: #fff !important;
  }
}

.userCardSmall {
  display: flex;
  align-items: center;
  gap: 20px;
  flex-wrap: wrap;
}

@media (max-width: 767px) {
  .userCardSmall {
    gap: 5px;
  }
}